import React from 'react';
import Modal from 'react-modal';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const customModalStyles = {
  content: {
    width: '80%', // Adjust the width as needed
    maxWidth: '600px',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center', // Center vertically
    alignItems: 'center', // Center horizontally
    borderRadius: '8%',
  },
};

const greenStyle = {
  backgroundColor: 'green',
  width: '64px',
  height: '64px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  padding: '16px',
};

const redStyle = {
  backgroundColor: 'red',
  width: '64px',
  height: '64px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  padding: '16px',
};

const AcceptOrDeclineModal = ({ isOpen, onRequestClose, registrationStatus, registrationError, registration }) => {
  
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel=""
      style={customModalStyles}
      registration={registration}
    >
      <div className="flex flex-col justify-between w-full text-center pb-6 items-center">
        
        <h2
          className={`text-xl sm:text-xl md:text-2xl lg:text-2xl xl:text-2xl py-2`}
        >
          {registration.firstName} {registration.middleName} {registration.lastName}
        </h2>
        

        <div className='px-4 '>
      
      <p>ID: {registration.id}</p>
      {/* <p>Name: <strong>{`${registration.firstName} ${registration.middleName} ${registration.lastName}`}</strong></p> */}
      <p>Email: {registration.email}</p>
      <p>Institution: {registration.institution}</p>
      <p>Phone Number: <strong>{registration.phoneNumber}</strong></p>
      <p>Attendance Type: <strong>{registration.attendanceType}</strong>, Status: <strong>{registration.status}</strong></p>
      {/* <p>Modules: <strong>{registration.modules}</strong></p> */}
      {/* <p>Modules: <strong>{registration.modules.map((moduleObj) => moduleObj.module).join(', ')}</strong></p> */}
      
      {/* <p>Modules:</p>
      <ul>
        {registration.modules.map((moduleObj) => (
          <li className='text-sm' key={moduleObj.id}>{moduleObj.module}</li>
          ))}
      </ul> */}

<p className='pt-4'>Modules:</p>
      {registration.modules && registration.modules.length > 0 && (
  <div>
    <ul>
      {registration.modules.map((moduleObj) => (
        <li className='text-xs font-bold' key={moduleObj.id}>{moduleObj.module}</li>
      ))}
    </ul>
  </div>
)}



      {/* <p>Workshop: <strong>{registration.workshop.title}</strong></p> */}

      {/* Display the payment evidence as an image */}
  {registration.payment && (
    <div className='pt-4'>
      <p>Payment Evidence:</p>
      <div className='flex justify-center items-center'>
      <img
        src={`https://workshop.kasuwa24.ng/${registration.payment}`}
        alt="Payment Evidence"
        style={{ 
          maxWidth: '30%',
          height: 'auto',
         }}
      />
      </div>
    </div>
  )}
      
      </div>

        
      </div>

      <div className='text-center ' style={{ display: 'flex', justifyContent: 'center' }}>
        <p className='text-center mb-4 text-red-500'
        style={{ 
          //color: 'red',//'#990000', 
          fontSize: '16px' }}>{registrationError}</p>
      </div>

      <button className="rounded-button-blue pt-4 shadow-lg" onClick={onRequestClose}>
        Done
      </button>
    </Modal>
  );
};

export default AcceptOrDeclineModal;
