import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../../Header';
import Footer from '../../Footer';
import dashboardImage from '../../../assets/frame.png';
import CallIcon from '@mui/icons-material/Call';
import MessageIcon from '@mui/icons-material/Message';
import logo from '../../../assets/zlogo.png';
import building from '../../../assets/building.png';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faMapMarker } from '@fortawesome/free-solid-svg-icons';

import RegistrationModal from '../modals/RegistrationModal';
import LoadingScreen from '../../LoadingScreen';


export default function SpecificPage() {
  const [loading, setLoading] = useState(false);
  const [workshopData, setWorkshopData] = useState({});
  const [selectedFile, setSelectedFile] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [error, setError] = useState(null);

  const [registrationStatus, setRegistrationStatus] = useState(null);
  const navigate = useNavigate();

  const [selectedModules, setSelectedModules] = useState([]);
  const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday'];
  const [resultString, setResultString] = useState('');

  const [attendanceType, setAttendanceType] = useState('');

  const [errorMessage, setErrorMessage] = useState('');

  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isSigninLoading, setIsSigninLoading] = useState(false);

  const staticModuleValues = [
  '9a5fbd40-3d4f-4ba2-bbeb-ec55d14a6751',
  '9a5fbd8d-8da7-4ca8-978d-68acdc51a476',
  '9a5fbdd7-639a-44c2-b4e9-57346186dfbd',
  '9a5fbe65-29db-4e80-a08d-48347ef7d802',
];
const staticModuleValueNames = [
  '( Mon 30th Oct, 2023 ) Opening Ceremony, Workshop On Cyber Security & Data Protection',
  '( Tue 31st Oct, 2023 ) Workshop On Networking & Cloud Computing',
  '( Wed 1st Nov, 2023 ) Workshop On Software & Web Development',
  '( Thu 2nd Nov, 2023 ) Workshop On Artificial Intelligence, Closing Ceremony',
]

  const pageStyle = {
    height: '100vh',
    margin: '0',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  };

  const flexCenter100 = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  };

  const imageStyle = {
    width: '100%',
    maxHeight: '100vh',
  };

  const imageStyleWorkshop = {
    width: '50%',
    //maxHeight: '100vh',
  };

  const contentStyle = {
    flex: '1',
    overflowY: 'auto',
  };

  const [firstName, setFirstName] = useState('Enter First Name');
  const [middleName, setMiddlename] = useState('Enter Middlename');
  const [lastName, setLastName] = useState('Enter Last Name');
  const [emailAddress, setEmailAddress] = useState('Enter Email Address');
  const [institution, setInstitution] = useState('Enter Institution');
  const [phoneNumber, setPhoneNumber] = useState('Enter Phone Number');
  const [resultFromWorkshop, setResultFromWorkshop] = useState(
    'Enter what you hope to achieve from the workshop'
  );

  const buttonContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const buttonStyle = {};

  let params = useParams();

  useEffect(() => {
    // alert("Effect triggered");
    handleData();

    const sortedDays = selectedModules.sort(
      (a, b) => days.indexOf(a) - days.indexOf(b)
    );
    setResultString(sortedDays.join(', '));
  }, [selectedModules]); // The empty dependency array means this effect runs once on mount

  const handleData = async () => {
    setLoading(true);
    setIsDataLoading(true);
    try {
      // alert(`here: ${params.id}`);
      const response = await axios.get(`https://workshop.kasuwa24.ng/api/public/${params.id}`, {
        headers: {
          'Content-Type': 'application/json',
          // Authorization: `Bearer ${token}`,
        },
      });

      // Check if the request was successful
      if (response.data.success) {
        const data = response.data.data;
        setWorkshopData(data);
        //alert(JSON.stringify(response.data.data));

        setLoading(false); // Set loading to false when data is received
        setIsDataLoading(false);
      } else {
        // Handle the case where the request was not successful
        console.error('Request failed:', response.data.message);
        setIsDataLoading(false);
      }
    } catch (error) {
      //alert(error);
      console.error('Request error:', error);
      setIsDataLoading(false);
      // Handle request error, e.g., display an error message to the user
    }
  };

  const handleRegistration = async () => {

    setErrorMessage('');
    setIsSigninLoading(true);

    //alert(selectedFile.name);
    //alert(selectedFile.name == null ? 'null' : 'notnull');
    //alert(JSON.stringify(selectedFile) === null ? 'null' : 'notnull');
    //alert(attendanceType);
    //alert(selectedValues);
    //return;

    const sm = selectedModules;//(JSON.stringify(selectedModules));

    if (middleName == 'Enter Middlename') {
      setMiddlename("");
    }


    // alert(
    //   'workshop: ' + params.id +
    //     '\nfirstName: ' + firstName +
    //     '\nmiddleName: ' + middleName +
    //     '\nsurname: ' + lastName +
    //     '\nemail: ' + emailAddress +
    //     '\ninstitution: ' + institution +
    //     '\nphoneNumber: ' + phoneNumber +
    //     '\nmodules: ' + sm +
    //     '\ntype: ' + attendanceType
    // );

    // Check if a file has been selected
  if (selectedFile.name == null) {
    alert("Please select a payment evidence file.");
    setIsSigninLoading(false);
    return;
  }

    // Check if other fields are valid
  if (
    firstName === "Enter First Name" ||
    middleName === "Enter Middle Name" ||
    lastName === "Enter Last Name" ||
    emailAddress === "Enter Email Address" ||
    institution === "Enter Institution" ||
    phoneNumber === "Enter Phone Number" ||
    attendanceType === "Select" ||
    selectedModules.length === 0
  ) {
    alert("Please fill out all required fields.");
    setIsSigninLoading(false);
    return;
  }

    //if (selectedFile != '' && attendanceType != 'Select' && selectedValues != '') 
    {
      try {
        const formData = new FormData();
        formData.append('workshop', params.id);
        formData.append('firstName', firstName);
        formData.append('middleName', middleName);
        formData.append('surname', lastName);
        formData.append('email', emailAddress);
        formData.append('institution', institution);
        formData.append('phoneNumber', phoneNumber);
        formData.append('modules', sm);
        formData.append('type', attendanceType);
  
        // Append the actual file to the formData
        formData.append('paymentEvidence', selectedFile);
  
        const response = await axios.post("https://workshop.kasuwa24.ng/api/public", formData, {
          headers: {
            'Content-Type': 'multipart/form-data', // Use multipart/form-data for file uploads
          },
        });
  
        if (response.data.success) {
          // Registration was successful, you can handle the response here
          //alert("ok");
          setRegistrationStatus("Registration Successful");
          setIsModalOpen(true);
          console.log('Registration successful:', response.data.message);
          // You can also redirect the user to a confirmation page or do any other actions.

          //alert("okay")
        } else {
          // Handle the case where registration was not successful
          //alert("not ok");
          setRegistrationStatus("Registration Failed");
          setIsModalOpen(true);
          console.error('Registration failed:', response.data);
          
          //setErrorMessage(response.data.message);
          setErrorMessage(response.data.message + ": " + response.data.data);
        }
        setIsSigninLoading(false);
      } catch (error) {
        setRegistrationStatus("Registration Failed");
        console.error('Registration Failed:', error);

        setIsModalOpen(true);

        setErrorMessage("Registration Failed");
        setIsSigninLoading(false);
        // Handle request error, e.g., display an error message to the user

        if (error.response && error.response.data && error.response.data.data) {
          const dataKeys = Object.keys(error.response.data.data);
      
          if (dataKeys.length > 0) {
            // Construct an error message from the data keys
            const errorMessage = dataKeys
              .map(key => {
                const messages = error.response.data.data[key];
                //alert(messages);
                //return `${key}: ${messages.join(', ')}`;
                //return `${messages.join(', ')}`;
                return `${messages}`;
              })
              .join('\n');
      
              setErrorMessage(errorMessage);
          } else {
            // If there are no data keys, set a generic error message
            setErrorMessage('An error occurred during registration.');
          }
        } else {
          // Handle other error cases if needed
          setErrorMessage('An error occurred during registration.');
        }
      }

    } 
    // else {
    //   alert("Select: Payment Evidence, Attendance Type and/or Attendance Date");
    // }

    
  }

  function getMonthAbbreviation(monthString) {
    const months = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    if (typeof monthString === 'string' && monthString.length === 2) {
      const monthNumber = parseInt(monthString);

      if (!isNaN(monthNumber) && monthNumber >= 1 && monthNumber <= 12) {
        return months[monthNumber - 1];
      }
    }

    return null;
  }

  const handlePaymentEvidenceChange = (e) => {
    const file = e.target.files[0];
  if (file) {
    setSelectedFile(file);
    //alert('File selected: ' + file.name);
    //alert(file.name);
  } else {
    alert('No file selected');
  }
  };

  const handleDropdownChange = (event) => {
    const selectedValue = event.target.value;
  setAttendanceType(selectedValue);
  //
  //alert(selectedValue);
  };

  const handleCheckboxChangeDynamic = (event, label) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      alert(label);
      // Append the label to the selected values
      setSelectedModules([...selectedModules, label]);

    } else {
      // Remove the label from the selected values
      const updatedValues = selectedModules.filter((value) => value !== label);
      setSelectedModules(updatedValues);
    }
    //
    //alert(updatedValues);
  };

  const handleCheckboxChange = (event, value) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      // Append the value to the selected values
      setSelectedModules([...selectedModules, value]);
      //alert(selectedValues);
    } else {
      // Remove the value from the selected values
      const updatedValues = selectedModules.filter((selectedValue) => selectedValue !== value);
      setSelectedModules(updatedValues);
    }
  };
  

  const openAddModal = () => {
    setIsModalOpen(true);
  };

  // Function to close the modal
  const closeAddModal = () => {
    setIsModalOpen(false);
    if (registrationStatus == "Registration Successful") {
      navigate("/");
    }
    
  };

  return (
    <div>
      <Header />
      <div style={{ ...pageStyle, ...contentStyle }}>
        <img src={dashboardImage} alt="Landing Page" style={imageStyle} />
        <div className="container mx-auto">
          <div className="grid grid-cols-1 gap-6">
            <div className="rounded-lg overflow-hidden p-4">
              <div className="p-6">
                <img
                  src={"https://workshop.kasuwa24.ng/" + workshopData.banner}
                  alt="Landing Page" style={imageStyle}
                />
              </div>
              <div className="flex p-6">
                {workshopData.startDate ? (
                  <div className="mr-4 flex flex-col items-center">
                    <div className="font-bold text-blue-500 text-xs text-center">
                      {getMonthAbbreviation(workshopData.startDate.substring(3, 5))}
                    </div>
                    <div className="font-bold text-lg text-center">
                      {workshopData.startDate.substring(0, 2)}
                    </div>
                    <div className="text-xs text-center">
                      {workshopData.startDate.substring(6, 11)}
                    </div>
                  </div>
                ) : null}

                <div>
                  <Link className="font-bold py-2" to="/workshop">
                    <div>{workshopData.title}</div>
                  </Link>
                  {/* <div>{workshopData.description}</div> */}
                  <div className='text-l text-green-700 mt-1'>Posted: {workshopData.startDate}</div>
                  <div className='text-3xl font-bold text-red-500 mt-1'>₦{workshopData.amount}</div>
                </div>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 px-8 gap-6 mb-8">

            <div className="flex items-center rounded-lg border-2 border-blue-500 p-3 ">
              <div className="mr-2  rounded-lg h-full">
                <FontAwesomeIcon icon={faClock} color="blue" />
              </div>
              <div className="font-bold mx-4 text-black">{workshopData.startTime}</div>
            </div>

            <div className="flex items-center rounded-lg border-2 border-blue-500 p-3">
              <div className="mr-2  rounded-lg h-full">
                <FontAwesomeIcon icon={faMapMarker} color="blue" />
              </div>
              <div className="font-bold mx-4 text-black">{workshopData.address}</div>
            </div>
          </div>


          <div className="grid grid-cols-1 px-8 gap-6 pb-4">
                  
                  <div className=''>{workshopData.description}</div>
                </div>


          {/* <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 gap-6">
            <div className="flex justify-center l p-6 pt-8" style={{ flex: '2' }}>
              <Link className="" to="result">
                <button className="rounded-button-blue">Load More</button>
              </Link>
            </div>
          </div> */}

          <div className=" ">
            <div style={flexCenter100}>
              <h2 className="text-2xl sm:text-full md:text-4xl lg:text-4xl xl:text-4xl text-blue-500 ">
                Registration
              </h2>
            </div>
          </div>

          <div className="flex justify-between ">
            <div
              className="rounded-lg p-4 m-2 flex-1 text-black"
              style={{ backgroundColor: '#ffffff' }}
            >
              <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-6 md:mx-16 sm:mx-2">
                <div className="flex flex-col md:flex-row py-2">
                  <div className="flex flex-col md:w-1/2 py-2 px-4">
                    <label>First Name</label>
                    <input
                      className="mt-2 p-2 focus border-blue-500 border-2 rounded-xl"
                      type="text"
                      placeholder={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </div>
                  <div className="flex flex-col md:w-1/2 py-2 px-4">
                    <label>Middlename</label>
                    <input
                      className="mt-2 p-2 focus border-blue-500 border-2 rounded-xl"
                      type="text"
                      placeholder={middleName}
                      onChange={(e) => setMiddlename(e.target.value)}
                    />
                  </div>
                  <div className="flex flex-col md:w-1/2 py-2 px-4">
                    <label>Last Name</label>
                    <input
                      className="mt-2 p-2 focus border-blue-500 border-2 rounded-xl"
                      type="text"
                      placeholder={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>
                </div>

                <div className="flex flex-col py-2 px-4">
                  <label>Email</label>
                  <input
                    className="mt-2 p-2 focus border-blue-500 border-2 rounded-xl"
                    type="text"
                    placeholder={emailAddress}
                    onChange={(e) => setEmailAddress(e.target.value)}
                  />
                </div>

                <div className="flex flex-col md:flex-row py-2">
                  <div className="flex flex-col md:w-1/2 py-2 px-4">
                    <label>Institution</label>
                    <input
                      className="mt-2 p-2 focus border-blue-500 border-2 rounded-xl"
                      type="text"
                      placeholder={institution}
                      onChange={(e) => setInstitution(e.target.value)}
                    />
                  </div>
                  <div className="flex flex-col md:w-1/2 py-2 px-4">
                    <label>Phone Number</label>
                    <input
                      className="mt-2 p-2 focus border-blue-500 border-2 rounded-xl"
                      type="text"
                      placeholder={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                  </div>
                </div>

                {/* <div className="flex flex-col py-2 px-4">
                  <label>Result from the workshop</label>
                  <input
                    className="mt-2 p-2 focus border-blue-500 border-2 rounded-xl"
                    type="text"
                    placeholder={resultFromWorkshop}
                    onChange={(e) => setResultFromWorkshop(e.target.value)}
                  />
                </div> */}

<div className="px-4" >
<div className="flex flex-col  py-2 px-4 border-blue-500 border-2 rounded-xl">
                    <label>Payment evidence</label>
                    
                    <div className="flex flex-col py-2 px-4">
                  <input
                  type="file"
                  id="paymentEvidenceInput"
                  accept=".jpg, .jpeg"
                  onChange={handlePaymentEvidenceChange}
                  />
                </div>
                  </div>
</div>
                

                  <div className="flex flex-col py-2 px-4">
        <label>Attendance Type:</label>
        <select 
          className="mt-2 p-2 focus border-blue-500 border-2 rounded-xl"
          onChange={(e) => handleDropdownChange(e)}
        >
          <option value="Select">Select</option>
          <option value="physical">Physical</option>
          <option value="virtual">Virtual</option>
        </select>
      </div>




<div className="px-4">
  <div className="flex flex-col py-2 px-6 border-blue-500 border-2 rounded-xl">
    <label>Select Module of Interest:</label>
    <div className="mt-2 mr-4 pb-4">


    {staticModuleValues.map((moduleValue, index) => (
    <div key={moduleValue}>
      <input
        type="checkbox"
        id={moduleValue}
        className="mt-6 p-2 mr-2 focus:border-blue-500 border-2 rounded-xl"
        onChange={(e) => handleCheckboxChange(e, moduleValue)}
      />
      <label htmlFor={moduleValue}>{staticModuleValueNames[index]}</label>
    </div>
  ))}


      {/* {
      
      
      workshopData.modules && workshopData.modules.length > 0 ?


      workshopData.modules.map((module) => (
        <div key={module.id}>
          <input
            type="checkbox"
            id={module.id}
            className="mt-6 p-2 mr-2 focus:border-blue-500 border-2 rounded-xl"
            onChange={(e) => handleCheckboxChange(e, module.id)}
          />
          <label htmlFor={module.id}>{module.name}</label>
        </div>
      )) 
      
      : 
      <div>Loading modules..</div>
      
      
      } */}

      
      {/* <div>
        
        {
        attendanceType == "physical" ? 
        <div>Price: {workshopData.amount} p</div> : 
        attendanceType == "virtual" ? 
        <div>Price: {workshopData.amount} v</div> :  
        attendanceType == "hybrid" ?
        <div className='flex flex-col'>
        <div>Price: {workshopData.amount} p</div>
        <div>Price: {workshopData.amount} v</div>
        </div>  : <div></div>
        
        }
        
        </div> */}



    </div>
  </div>
</div>




      

      

              </div>

              
              


              {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <p style={{ color: '#990000', fontSize: '12px' }}>{errorMessage}</p>
                                        </div> */}

              
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 gap-6">
                <div className="flex justify-center l p-6 pt-8" style={{ flex: '2' }}>
                  <button className="rounded-button-blue" onClick={!isSigninLoading ? handleRegistration : ()=>{}}>
                    
                  {isSigninLoading ? (
                      <LoadingScreen />
                    ) : (
                      'Register'
                    )}
                    </button>
                </div>
              </div>

              
            </div>
          </div>


          <RegistrationModal
              isOpen={isModalOpen}
              onRequestClose={closeAddModal}
              registrationStatus={registrationStatus}
              registrationError={errorMessage}
            />

          <Footer />
        </div>
      </div>
    </div>
  );
}
