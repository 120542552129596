import React, { useState, useEffect, useRef } from 'react';
import nuhu from '../../../assets/zlogo.png';
import { Link, useNavigate } from 'react-router-dom';

import axios from 'axios';
import LoadingScreen from '../../LoadingScreen';

import DatePicker from 'react-datepicker';
import './Datepicker.css';
import { format } from 'date-fns';

import WorkshopRegistrationModal from '../modals/WorkshopRegistrationModal';


//import TimePicker from 'react-time-picker';
//import { TimePicker, Row, Col } from '@uiw/react-time-picker';


export default function ComponentCreateWorkshop() {
  const navigate = useNavigate();

  //const [title, setTitle] = useState('Enter Workshop title');
  const [subTitle, setSubtitle] = useState('Enter Worshop title');
  //const [description, setDescription] = useState('Enter Description');

  const [firstName, setFirstName] = useState('Enter First Name');
  const [lastName, setLastName] = useState('Enter Last Name');
  const [emailaddress, setEmailaddress] = useState('Enter Email Address');
  const [institution, setInstitution] = useState('Enter Institution');
  const [phoneNumber, setPhoneNumber] = useState('Enter Phone Number');
  const [resultFromWorkshop, setResultFromWorkshop] = useState(
    'Enter what you hope to achieve from the workshop'
  );

  const [activeTab, setActiveTab] = useState('basicInfo'); // Initialize the active tab


  //
  const [title, setTitle] = useState('');
  const [organization, setOrganization] = useState('');
  const [description, setDescription] = useState('');
  const [banner, setBanner] = useState({}); // For file input, you might use useState(null)
  const [type, setType] = useState([]); // You may initialize this with a default value
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [virtualLink, setVirtualLink] = useState('');
  const [address, setAddress] = useState('');
  const [amount, setAmount] = useState('');
  //

  const [errorMessage, setErrorMessage] = useState('');
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isSigninLoading, setIsSigninLoading] = useState(false);

  const [registrationStatus, setRegistrationStatus] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isNetworkLoading, setIsNetworkLoading] = useState(false);

  const fileInputRef = useRef(null);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  let token = localStorage.getItem('token');
  useEffect(() => {
    if (!token) {
      // Redirect to the home page if the token is null
      navigate('/');
    } else {
      // If the user is authenticated, call the handleData function
      //handleData();
    }
  }, [navigate]);


  const clearFields = () => {
    //
    setTitle('');
    setOrganization('');
    setDescription('');
    setBanner({}); 
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    setType([]); 
    setStartDate('');
    setEndDate('');
    setStartTime('');
    setEndTime('');
    setVirtualLink('');
    setAddress('');
    setAmount('');
          //
  }


  const createWorkshop = async () => {

    // Check if a file has been selected
  if (setBanner.name == null) {
    alert("Please select a payment evidence file.");
    setIsNetworkLoading(false);
    return;
  }

  //   // Check if other fields are valid
  if (
  //   title === "" ||
  // organization === "" ||
  // description === "" ||
  // banner === "" ||
  // type === "" ||
  startDate === "" ||
  endDate === "" 
  // startTime === "" ||
  // endTime === "" ||
  // virtualLink === "" ||
  // address === "" ||
  // amount === ""

  ) {
    alert("Confirm Start Date and End Date.");
    setIsNetworkLoading(false);
    return;
  }




    //alert("!");
    let formattedDate = startDate.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }).replace(/\//g, '-');    
    let sd = formattedDate;

    formattedDate = startDate.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }).replace(/\//g, '-');
    let ed = formattedDate;

    //alert(sd + " " + ed);
  
    

    // alert("Alert For testing willbe removed: \n" +
    //   "title: " + title + "\n" +
    //   "organization: " + organization + "\n" +
    //   "description: " + description + "\n" +
    // "banner :" + banner + "\n" +
    // "type: " + type + "\n" +
    // "startDate: "+ sd + "\n" +
    // "endDate: " + ed + "\n" +
    // "startTime: "+ startTime + "\n" +
    // "endTime: "+ endTime + "\n" +
    // "virtualLink: "+ virtualLink + "\n" +
    // "address: "+ address + "\n" +
    // "amount: "+ amount
    // );


    setErrorMessage('');
    setIsNetworkLoading(true);


    //alert(selectedFile.name);
    //alert(selectedFile.name == null ? 'null' : 'notnull');
    //alert(JSON.stringify(selectedFile) === null ? 'null' : 'notnull');
    //alert(attendanceType);
    //alert(selectedValues);
    //return;

    

    //if (selectedFile != '' && attendanceType != 'Select' && selectedValues != '') 
    {
      try {
        const formData = new FormData();
        //formData.append('workshop', params.id);
        formData.append('title', title);
        formData.append('organization', organization);
        formData.append('description', description);
        formData.append('banner', banner);
        formData.append('type', type);
        formData.append('startDate', sd);
        formData.append('endDate', ed);
        formData.append('startTime', startTime);
        formData.append('endTime', endTime);
        formData.append('virtualLink', virtualLink);
        formData.append('address', address);
        formData.append('amount', amount);
  
        // Append the actual file to the formData
        //formData.append('paymentEvidence', selectedFile);
        
  
        const response = await axios.post("https://workshop.kasuwa24.ng/api/admin/workshops", formData, {
          headers: {
            'Content-Type': 'multipart/form-data', // Use multipart/form-data for file uploads
            Authorization: `Bearer ${token}`,
          },
        });
  
        if (response.data.success) {
          // Registration was successful, you can handle the response here
          //alert("Workshop Creation Successful !!");
          setRegistrationStatus("Registration Successful");
          setIsModalOpen(true);
          console.log('Workshop Creation successful:', response.data.message);
          // You can also redirect the user to a confirmation page or do any other actions.
          clearFields();
          //navigate('/zaptrance-workshop');

          //alert("okay")
        } else {
          // Handle the case where registration was not successful
          //alert("not ok");
          setRegistrationStatus("Registration Failed");
          setIsModalOpen(true);
          console.error('Workshop Creation failed:', response.data);
          
          //setErrorMessage(response.data.message);
          setErrorMessage(response.data.message + ": " + response.data.data);
        }
        setIsNetworkLoading(false);
      } catch (error) {
        setRegistrationStatus("Registration Failed");
        console.error('Workshop Creation Failed:', error);

        setIsModalOpen(true);

        setErrorMessage("Workshop Creation Failed");
        setIsNetworkLoading(false);
        // Handle request error, e.g., display an error message to the user

        if (error.response && error.response.data && error.response.data.data) {
          const dataKeys = Object.keys(error.response.data.data);
      
          if (dataKeys.length > 0) {
            // Construct an error message from the data keys
            const errorMessage = dataKeys
              .map(key => {
                const messages = error.response.data.data[key];
                //alert(messages);
                //return `${key}: ${messages.join(', ')}`;
                //return `${messages.join(', ')}`;
                return `${messages}`;
              })
              .join('\n');
      
              setErrorMessage(errorMessage);
          } else {
            // If there are no data keys, set a generic error message
            setErrorMessage('An error occurred during registration.');
          }
        } else {
          // Handle other error cases if needed
          setErrorMessage('An error occurred during registration.');
        }
      }

    } 
    

  };

  

  const closeAddModal = () => {
    setIsModalOpen(false);
    if (registrationStatus == "Registration Successful") {
      navigate("/zaptrance-workshop");
    }
    
  };


  return (
    <div>
      <div className="flex">
        <div className="text-black px-4 py-4" style={{ borderBottom: '2px solid #2353FF' }}>
        New Workshop
        </div>

        <div className="flex flex-grow items-center justify-end">
          <div>
            {/* <div className={`inline-block p-2 mx-2 rounded-full border-2 border-green-500 px-8 text-green-500 font-bold`}> */}
            <div className={`inline-block p-4 mx-2 rounded-full bg-gray-200 px-8`}>
              Workshop Type</div>
          </div>
          
        </div>
      </div> 
      {/* <div className="flex">
        <div className="flex">
          <div className="text-black px-4 py-4" style={{ borderBottom: '2px solid #2353FF' }}>
            New Workshop
          </div>
        </div>

        <div className="flex flex-grow justify-end">
          <div className={`inline-block p-4 mx-2 rounded-full bg-gray-200 px-8`}>Workshop Type</div>
        </div>
      </div> */}

      <div className="overflow-x-auto ">
        <div>
          <div className="overflow-x-auto ">
            {/* Tab Buttons */}
            {/* <div className="flex gap-6">
              <div
                className={`flex justify-center l p-6 pt-8 ${activeTab === 'basicInfo' ? '' : ''}`}
                style={{ flex: '2' }}
                onClick={() => handleTabClick('basicInfo')}
              >
                <button className={` ${activeTab === 'basicInfo' ? 'text-white rounded-button-blue shadow-lg' : ''}`} style={{}}>
                  Basic Info
                </button>
              </div>
              <div
                className={`flex justify-center l p-6 pt-8 ${activeTab === 'requirements' ? '' : ''}`}
                style={{ flex: '2' }}
                onClick={() => handleTabClick('requirements')}
              >
                <button className={` ${activeTab === 'requirements' ? 'text-white rounded-button-blue shadow-lg' : ''}`} style={{}}>
                  Requirements
                </button>
              </div>
              <div
                className={`flex justify-center l p-6 pt-8 ${activeTab === 'forms' ? '' : ''}`}
                style={{ flex: '2' }}
                onClick={() => handleTabClick('forms')}
              >
                <button className={` ${activeTab === 'forms' ? 'text-white rounded-button-blue shadow-lg' : ''}`} style={{}}>
                  Forms
                </button>
              </div>
            </div> */}

            {/* Content */}
            {activeTab === 'basicInfo' && (
              <div className="text-xs text-black px-4 py-4">
                
                <div className="flex flex-col py-2 px-4">
        <label>Title</label>
        <input
          className="mt-2 p-2 focus border-blue-500 border-2 rounded-xl"
          type="text"
          placeholder="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>

      <div className="flex flex-col py-2 px-4">
        <label>Organization</label>
        <input
          className="mt-2 p-2 focus border-blue-500 border-2 rounded-xl"
          type="text"
          placeholder="Organization"
          value={organization}
          onChange={(e) => setOrganization(e.target.value)}
        />
      </div>

      <div className="flex flex-col py-2 px-4">
        <label>Description</label>
        <input
          className="mt-2 p-2 focus border-blue-500 border-2 rounded-xl"
          type="text"
          placeholder="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </div>

      <div className="flex flex-col py-2 px-4">
        <label>Banner</label>
        <input
          className="mt-2 p-2 focus border-blue-500 border-2 rounded-xl"
          type="file"
          accept="image/*"
          onChange={(e) => setBanner(e.target.files[0])}
          ref={fileInputRef}
        />
      </div>

      <div className="flex flex-col py-2 px-4">
        <label>Type</label>
        <select
          className="mt-2 p-2 focus border-blue-500 border-2 rounded-xl"
          value={type}
          onChange={(e) => setType(e.target.value)}
        >
          <option value="">Select Type</option>
          <option value="virtual">Virtual</option>
          <option value="hybrid">Hybrid</option>
          <option value="physical">Physical</option>
        </select>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
      <div className="flex flex-col py-2 pl-4 pr-1">
  <label>Start Date</label>
  <DatePicker
        selected={startDate}
        onChange={(date) => {
          //
          setStartDate(date);
        }}
        dateFormat="dd-MM-yyyy"
        placeholderText="Select a Startdate"
        className="mt-2 p-2 focus border-blue-500 border-2 rounded-xl w-full"
      />
</div>

{/* <div className="flex flex-col py-2 pl-1 pr-4"> */}
<div className="flex flex-col py-2 pl-4 pr-1">
  <label>End Date</label>
  <DatePicker
        selected={endDate}
        onChange={(date) => {
          //
          setEndDate(date);
        }}
        dateFormat="dd-MM-yyyy"
        placeholderText="Select an Enddate"
        className="mt-2 p-2 focus border-blue-500 border-2 rounded-xl w-full"
      />

</div>
        </div>


        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
        <div className="flex flex-col py-2 pl-4 pr-1">
  <label>Start Time</label>
  <input
    className="mt-2 p-2 focus border-blue-500 border-2 rounded-xl"
    type="text"
    maxLength='7'
    placeholder="Enter Start Time as HH:MMam/pm"
    value={startTime}
    onChange={(e) => setStartTime(e.target.value.replace(" ", ""))}
  />
</div>

{/* <div className="flex flex-col py-2 pl-1 pr-4"> */}
<div className="flex flex-col py-2 pl-4 pr-1">
  <label>End Time</label>
  <input
    className="mt-2 p-2 focus border-blue-500 border-2 rounded-xl"
    type="text"
    maxLength='7'
    placeholder="Enter End Time as HH:MMam/pm"
    value={endTime}
    onChange={(e) => setEndTime(e.target.value.replace(" ", ""))}
  />
</div>

        </div>

        




<div className="flex flex-col py-2 px-4">
  <label>Virtual Link</label>
  <input
    className="mt-2 p-2 focus border-blue-500 border-2 rounded-xl"
    type="text"
    placeholder="Virtual Link"
    value={virtualLink}
    onChange={(e) => setVirtualLink(e.target.value)}
  />
</div>

<div className="flex flex-col py-2 px-4">
  <label>Address</label>
  <input
    className="mt-2 p-2 focus border-blue-500 border-2 rounded-xl"
    type="text"
    placeholder="Address"
    value={address}
    onChange={(e) => setAddress(e.target.value)}
  />
</div>

<div className="flex flex-col py-2 px-4">
  <label>Amount</label>
  <input
    className="mt-2 p-2 focus border-blue-500 border-2 rounded-xl"
    type="number"
    placeholder="Amount"
    value={amount}
    onChange={(e) => setAmount(e.target.value)}
  />
</div>

<div className="flex flex-col py-2 px-4">
<div className='text-center' style={{ display: 'flex', justifyContent: 'center' }}>
        <p className='text-center mb-4 text-red-500'
        style={{ 
          //color: 'red',//'#990000', 
          fontSize: '12px' 
        }}>
          {errorMessage}
          </p>
      </div>

  </div>



<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 gap-6">
                      <div className="flex justify-center l p-6 pt-8" style={{ flex: '2' }}>
                      <button onClick={createWorkshop} className="rounded-button-blue">
                        
                        
                        {isNetworkLoading ? (
                      <LoadingScreen />
                    ) : (
                      'Create'
                    )}

                        </button>
                      </div>
                    </div>
                
              </div>
            )}
            {activeTab === 'requirements' && (
              <div className="text-xs text-black px-4 py-4">
                <div className="flex flex-col py-2 px-4">
                  <label>Description</label>
                  <input
                    className="mt-2 p-2 focus border-blue-500 border-2 rounded-xl"
                    type="text"
                    placeholder={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
              </div>
            )}
            {activeTab === 'forms' && (
              <div className="text-xs text-black px-4 py-4">
                <div className="flex justify-between">
                  <div
                    className="rounded-lg p-4 m-2 flex-1 text-black"
                    style={{ backgroundColor: '#ffffff' }}
                  >
                    <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-6">
                      <div className="flex flex-col md:flex-row py-2">
                        <div className="flex flex-col md:w-1/2 py-2 px-4">
                          <label>First Name</label>
                          <input
                            className="mt-2 p-2 focus border-blue-500 border-2 rounded-xl"
                            type="text"
                            placeholder={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                          />
                        </div>
                        <div className="flex flex-col md:w-1/2 py-2 px-4">
                          <label>Last Name</label>
                          <input
                            className="mt-2 p-2 focus border-blue-500 border-2 rounded-xl"
                            type="text"
                            placeholder={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="flex flex-col py-2 px-4">
                        <label>Email</label>
                        <input
                          className="mt-2 p-2 focus border-blue-500 border-2 rounded-xl"
                          type="text"
                          placeholder={emailaddress}
                          onChange={(e) => setEmailaddress(e.target.value)}
                        />
                      </div>

                      <div className="flex flex-col md:flex-row py-2">
                        <div className="flex flex-col md:w-1/2 py-2 px-4">
                          <label>Institution</label>
                          <input
                            className="mt-2 p-2 focus border-blue-500 border-2 rounded-xl"
                            type="text"
                            placeholder={institution}
                            onChange={(e) => setInstitution(e.target.value)}
                          />
                        </div>
                        <div className="flex flex-col md:w-1/2 py-2 px-4">
                          <label>Phone Number</label>
                          <input
                            className="mt-2 p-2 focus border-blue-500 border-2 rounded-xl"
                            type="text"
                            placeholder={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="flex flex-col py-2 px-4">
                        <label>Result from the workshop</label>
                        <input
                          className="mt-2 p-2 focus border-blue-500 border-2 rounded-xl"
                          type="text"
                          placeholder={resultFromWorkshop}
                          onChange={(e) => setResultFromWorkshop(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 gap-6">
                      <div className="flex justify-center l p-6 pt-8" style={{ flex: '2' }}>
                        <Link className="" to="/login">
                          <button className="rounded-button-blue">Ask Question</button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <WorkshopRegistrationModal
              isOpen={isModalOpen}
              onRequestClose={closeAddModal}
              registrationStatus={registrationStatus}
              registrationError={errorMessage}
            />

    </div>
  );
}
