import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../../Header';
import Footer from '../../Footer';

import AdminSideNav from './AdminSideNav';
import AdminMobileNavbar from './AdminMobileNavbar';

import HeaderAdmin from './HeaderAdmin';
import axios from 'axios';

import WorkshopComponent from './ComponentWorkshop';
import RegistrationComponent from './ComponentRegistration';
import CreateWorkshopComponent from './ComponentCreateWorkshop';

const Workshop = () => {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState('workshop');

  
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array to run this effect only once
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 640);
  };


  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <div>
       <div>
        {isMobile ? (
        <AdminMobileNavbar /> // Use the mobile navbar component for mobile
      ) : (
        <null /> // Use the desktop navbar component for desktop
      )}
      </div>



      <div className="flex">


{/* <AdminSideNav /> */}           
{isMobile ? (
          null
         // Use the mobile navbar component for mobile
      ) : (
        <AdminSideNav /> // Use the desktop navbar component for desktop
      )}
      
      
      <div className="content flex-grow">
        <div className="inner-content">
          <div className="p-4" style={{ backgroundColor: '#F6F7FB' }}>
            <div className="text-black px-4 pt-4">Zaptrance Systems</div>
            <div className='text-gray-600 px-4 pt-2 text-left text-2xl sm:text-full md:text-4xl lg:text-4xl xl:text-4xl font-bold mb-8'>
              Workshops</div>

 
            <div className="text-l font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
            <ul className="flex flex-wrap -mb-px">
              <li className="mr-2">
                <a
                  href="#"
                  className={`inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 ${activeTab === 'workshop' ? 'text-blue-600 border-blue-600' : ''}`}
                  onClick={() => handleTabClick('workshop')}
                >
                  Workshop
                </a>
              </li>
              <li className="mr-2">
                <a
                  href="#"
                  className={`inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 ${activeTab === 'registration' ? 'text-blue-600 border-blue-600' : ''}`}
                  onClick={() => handleTabClick('registration')}
                >
                  Registration
                </a>
              </li>
              <li className="mr-2">
                <a
                  href="#"
                  className={`inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 ${activeTab === 'createworkshop' ? 'text-blue-600 border-blue-600' : ''}`}
                  onClick={() => handleTabClick('createworkshop')}
                >
                  Create Workshop
                </a>
              </li>
              
              
            </ul>
          </div>

          {/* Tab content */}
          <div className="p-4">
            {activeTab === 'workshop' && <div> <WorkshopComponent /> </div>}
            {activeTab === 'registration' && <div> <RegistrationComponent /> </div>}
            {activeTab === 'createworkshop' && <div> <CreateWorkshopComponent /> </div>}
          </div>



          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Workshop;
