import React from 'react';
import logo from '../assets/zlogo.png';
import { Link } from 'react-router-dom';

export default function Header() {
  const buttonContainerStyle = {
    // Center horizontally on small screens and add padding
    display: 'flex',
    justifyContent: 'center',
    padding: '1rem', // Adjust the padding as needed
  };

  const buttonStyle = {
    // Add your button styles here
  };

  return (
    <div className='grid grid-cols-1 sm:grid-cols-2 items-center container mx-auto'>
      <div className='flex items-center'>
      <Link to="/">
      <img
          src={logo}
          alt='Logo'
        />
          </Link>
        
        <Link to="/">
            <button className='px-4'>
              Home
            </button>
          </Link>

          <Link to="/">
            <button className='px-4'>
              About
            </button>
          </Link>

          <Link to="/">
            <button className='px-4'>
              Contact
            </button>
          </Link>
      </div>

      <div>
        <div style={buttonContainerStyle}>
          <Link to="/">
            <button className="rounded-empty" style={buttonStyle}>
              .
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}
