//import HomeIcon from '@mui/icons-material/Home';
//import TravelExploreIcon from '@mui/icons-material/TravelExplore';
//import BarChartIcon from '@mui/icons-material/BarChart';
//import SettingsIcon from '@mui/icons-material/Settings';

//Home
//ViewProfile
//Payments
//RideHistory
//Support
//About
//Settings
//Logout
//BecomeADriver

import HomeIcon from '@mui/icons-material/Home';
import AppsIcon from '@mui/icons-material/Apps';
import WorkshopIcon from '@mui/icons-material/Wysiwyg';
import SupportIcon from '@mui/icons-material/Https';
import ProfileIcon from '@mui/icons-material/BarChart';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';



export const navData = [
    // {
    //     id: 0,
    //     icon: <HomeIcon/>,
    //     text: "Home",
    //     link: "home"
    // },
    {
        id: 1,
        icon: <AppsIcon/>,
        text: "Dashboard",
        link: "zaptrance-dashboard"
    },
    {
        id: 2,
        icon: <WorkshopIcon/>,
        text: "Workshop",
        link: "zaptrance-workshop"
    },
    {
        id: 3,
        icon: <SupportIcon/>,
        text: "Support",
        link: "zaptrance-support"
    },
    {
        id: 4,
        icon: <ProfileIcon/>,
        text: "Profile",
        link: "zaptrance-profile"
    },
    {
        id: 5,
        icon: <SettingsIcon/>,
        text: "Settings",
        link: "zaptrance-setting"
    },    
    // {
    //     id: 5,
    //     icon: <LogoutIcon/>,
    //     text: "Logout",
    //     link: "zaptrance-logout"
    // },
]