import React from 'react';
import Modal from 'react-modal';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const customModalStyles = {
  content: {
    width: '80%', // Adjust the width as needed
    maxWidth: '600px',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center', // Center vertically
    alignItems: 'center', // Center horizontally
    borderRadius: '8%',
  },
};

const greenStyle = {
  backgroundColor: 'green',
  width: '64px',
  height: '64px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  padding: '16px',
};

const redStyle = {
  backgroundColor: 'red',
  width: '64px',
  height: '64px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  padding: '16px',
};

const RegistrationModal = ({ isOpen, onRequestClose, registrationStatus, registrationError }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel=""
      style={customModalStyles}
    >
      <div className="flex flex-col justify-between w-full text-center pb-6 items-center">
        <div className="pt-8 pb-8">
          {registrationStatus === 'Registration Successful' ? (
            <FontAwesomeIcon
              icon={faCheck}
              color="white"
              className="ml-4 mr-4"
              style={{ ...greenStyle }}
            />
          ) : (
            <FontAwesomeIcon
              icon={faTimes}
              color="white"
              className="ml-4 mr-4"
              style={{ ...redStyle }}
            />
          )}
        </div>
        <h2
          style={{ fontSize: '2rem', marginTop: '0' }}
          className={`text-2xl sm:text-2xl md:text-4xl lg:text-4xl xl:text-4xl ${
            registrationStatus === 'Registration Successful'
              ? 'text-green-500'
              : 'text-red-500'
          } py-2`}
        >
          {registrationStatus === 'Registration Successful'
            ? 'Registration Successful'
            : 'Registration Failed'}
        </h2>
        <div className="pt-4">
          {registrationStatus === 'Registration Successful'
            ? 'Check your email for workshop reservation ticket details'
            : ''}
        </div>
      </div>

      <div className='text-center ' style={{ display: 'flex', justifyContent: 'center' }}>
        <p className='text-center mb-4 text-red-500'
        style={{ 
          //color: 'red',//'#990000', 
          fontSize: '16px' }}>{registrationError}</p>
      </div>

      <button className="rounded-button-blue pt-4 shadow-lg" onClick={onRequestClose}>
        Done
      </button>
    </Modal>
  );
};

export default RegistrationModal;
