import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import HeaderLogin from '../../HeaderLogin';
import Footer from '../../Footer';
import dashboardImage from '../../../assets/login.png';
import LoadingScreen from '../../LoadingScreen';
import logo from '../../../assets/zlogo.png';
import axios from 'axios';

export default function LoginPage() {
  const [email, setEmail] = useState('Enter your email');
  const [password, setPassword] = useState('Enter your password');
  const [isSigninLoading, setIsSigninLoading] = useState(false);

  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState('');

  const pageStyle = {
    height: '100vh',
    margin: '0',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  };

  const contentStyle = {
    flex: '1',
    overflowY: 'auto',
  };

  const imageStyle = {
    width: '100%',
    maxHeight: '100vh',
    position: 'relative', // Make the image container relative
  };


  const imageStyleTwo = {
    width: '100%',      // Make the image fill the container width
    maxHeight: '100vh', // Ensure the image doesn't exceed the viewport height
  };

  const loginContainerStyle = {
    position: 'absolute', // Position the login container absolutely
    top: '50%', // Center vertically
    left: '50%', // Center horizontally
    transform: 'translate(-50%, -50%)', // Center both horizontally and vertically
    backgroundColor: '#ffffff', // Add a background color
    padding: '20px',
    borderRadius: '10px',
    width: '80%', // Set the width of the login container
    maxWidth: '500px', // Add a maximum width for responsiveness
  };

  const buttonContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
  };

  const buttonStyle = {};




  const handleLogin = async () => {
    setIsSigninLoading(true);
    // try {
    //   // Perform login logic
    //   alert("here");
    //   //navigate("/zaptrance-dashboard");
      
    // } catch (error) {
    //   setIsSigninLoading(false);
    //   alert("error");
    //   console.error('Login error:', error);
    // }
    setIsSigninLoading(false);
  };


     // Function to handle the login button click
     const handleAdminLogin = async () => {

      setIsSigninLoading(true);
      setErrorMessage('');
  

      // if (
      //     email  == 'Enter your email'  ||  email  == '' ||
      //     password == 'Enter your password' ||  password  == ''
      //     ) {
              
      //         setErrorMessage("Login Failed: Please enter valid credentials");
      //     setIsSigninLoading(false);
      //     return;
      //   }
    
  
      
  
      try {
          // Make the login request to obtain the token
          const response = await axios.post('https://workshop.kasuwa24.ng/api/admin/login', {
              email,
              password,
          });

          localStorage.setItem('email', '');
          localStorage.setItem('password', '');
  
          // Check if the login was successful
          if (response.data.success && response.data.data.token) {
  
            setIsSigninLoading(false);
              
              // Token exists in the response, store it
              const token = response.data.data.token;
              //alert("login token: " + token)
              localStorage.setItem('token', token);
              //alert("stored token: " + localStorage.getItem('token'))
              //alert("login: " + JSON.stringify(response.data.data, null, 2));
  
  
              navigate("/zaptrance-dashboard");
              
              
          } else {
              setIsSigninLoading(false);
  
              setErrorMessage(response.data.message);
  
              // Handle the case where the login was not successful
              //console.error('Login failed:', response.data.message);
          }
      } catch (error) {
          setIsSigninLoading(false);

  
          //Request failed with status code 401
          setErrorMessage('Login failed. Please check your credentials and try again.');
  
          //console.error('Login error:', error);
          //console.error('Login error:', error);
          // Handle login error, e.g., display an error message to the user
      }
  };


  return (
    <div>
      <HeaderLogin />
      <img style={imageStyleTwo} src={dashboardImage} alt="Landing Page" />
      <div className="container mx-auto" style={loginContainerStyle}>
        <div style={imageStyle}>
          
        <div className="space-y-2">
              <div className="flex flex-col justify-center rounded-2xl">
                <form className="w-full mx-auto p-8 px-8 bg-white ">
                  <div className='grid grid-cols-1 pb-4'>
                    <div className='flex items-center justify-center'>
                      <img
                        src={logo}
                        alt='Logo'
                      />
                    </div>
                  </div>
                  <div className="flex flex-col py-2">
                    <label>Email Address</label>
                    <input
                      style={{ backgroundColor: '#EDF2F6' }}
                      className="rounded-lg mt-2 p-2 focus"
                      type="text"
                      placeholder={email}
                        //value={email}
                      onChange={(e) => {setEmail(e.target.value)}}
                    />
                  </div>
                  <div className="flex flex-col py-2">
                    <label>Password</label>
                    <input
                      style={{ backgroundColor: '#EDF2F6' }}
                      className="rounded-lg mt-2 p-2 focus"
                      type="password"
                      placeholder={password}
                        //value={password}
                      onChange={(e) => {setPassword(e.target.value)} }
                    />
                  </div>
                  <div style={buttonContainerStyle} className="pt-2 text-center">

                    
                    {isSigninLoading ? (
                      <button className="rounded-button" ><LoadingScreen /></button>
                    ) : (
                      <Link className="rounded-button" style={buttonStyle} onClick={handleAdminLogin}>
                        <strong>Admin Login</strong>
                      </Link>
                    )}
                  </div>
                </form>
              </div>
            </div>
        </div>
        
      </div>
      <div className="container mx-auto">

      <Footer />
      </div>
    </div>
  );
}
