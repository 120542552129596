import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../Header';
import Footer from '../../Footer';
import dashboardImage from '../../../assets/frame.png';
import CallIcon from '@mui/icons-material/Call';
import MessageIcon from '@mui/icons-material/Message';
import logo from '../../../assets/zlogo.png';
import building from '../../../assets/building.png';
import seat from '../../../assets/seat.png';
import abuz from '../../../assets/clients/abuz.png';
import iaict from '../../../assets/clients/iaict.png';
import kp from '../../../assets/clients/kp.png';
import nbte from '../../../assets/clients/nbte.png';
import ncatz from '../../../assets/clients/ncatz.png';
import pti from '../../../assets/clients/pti.png';
import axios from 'axios';

export default function LandingPage() {
  const [listOfWorkshops, setListOfWorkshops] = useState([]);
  const [filteredWorkshops, setFilteredWorkshops] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  
  useEffect(() => {
    handleData();
  }, []);

  const handleData = async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://workshop.kasuwa24.ng/api/public', {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.data.success) {
        const workshopsData = response.data.data;
        setListOfWorkshops(workshopsData);
        setFilteredWorkshops(workshopsData);
        setLoading(false);
      } else {
        console.error('Request failed:', response.data.message);
      }
    } catch (error) {
      console.error('Request error:', error);
    }
  };

  const handleSearchChange = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = listOfWorkshops.filter((workshop) =>
      workshop.name.toLowerCase().includes(query) ||
      workshop.place.toLowerCase().includes(query) ||
      workshop.time.toLowerCase().includes(query)
    );
    setFilteredWorkshops(filtered);
  };


  const pageStyle = {
    height: '100vh',
    margin: '0',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  };

  const flexCenter100 = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  };

  const imageStyle = {
    width: '100%',
    maxHeight: '100vh',
  };

  const blackDivStyle = {
    backgroundColor: 'black',
    borderRadius: '20px',
    padding: '20px',
    color: 'white',
    marginTop: '-20px',
  };

  const contentStyle = {
    flex: '1', // Make the content take up the remaining vertical space
    overflowY: 'auto', // Add vertical scroll when necessary
  };

  function getMonthAbbreviation(monthString) {
    const months = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
  
    if (typeof monthString === 'string' && monthString.length === 2) {
      const monthNumber = parseInt(monthString);
  
      if (!isNaN(monthNumber) && monthNumber >= 1 && monthNumber <= 12) {
        return months[monthNumber - 1];
      }
    }
  
    return null;
  }

  return (
    <div>
      <Header />
      <div style={{ ...pageStyle, ...contentStyle }}>
        <img
          src={dashboardImage}
          alt="Landing Page"
          style={imageStyle}
        />
        <div className="container mx-auto">
          <div className="grid px-6">
            <div style={{ ...blackDivStyle }} className="rounded-lg overflow-hidden shadow-2xl p-4" >
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
                <div className="p-2 px-6">
                  <label htmlFor="input1" className="text-white">Search Workshop</label>
                  <input
                    type="text"
                    id="input1"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    style={{
                      background: 'transparent',
                      borderBottom: '1px solid white',
                      outline: 'none'
                    }}
                    className="w-full p-2 border-none "
                  />
                </div>
                <div className="p-2 px-6">
                  <label htmlFor="input2" className="text-white">Place</label>
                  <input
                    type="text"
                    id="input2"
                    style={{
                      background: 'transparent',
                      borderBottom: '1px solid white',
                      outline: 'none'
                    }}
                    className="w-full p-2 border-none "
                  />
                </div>
                <div className="p-2 px-6">
                  <label htmlFor="input3" className="text-white">Time</label>
                  <input
                    type="text"
                    id="input3"
                    style={{
                      background: 'transparent',
                      borderBottom: '1px solid white',
                      outline: 'none'
                    }}
                    className="w-full p-2 border-none "
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-6">
            <div className="flex pt-4 mt-8 mb-8">
              <div className="flex ml-6">
                <div style={flexCenter100}>
                  <h2 className="text-left text-2xl sm:text-full md:text-4xl lg:text-4xl xl:text-4xl  ">
                    Upcoming Workshops
                  </h2>
                </div>
              </div>
              <div className="flex flex-grow justify-end">
                <div className={`inline-block p-4 mx-2 rounded-full bg-gray-200 px-8`}>Title</div>
                <div className={`inline-block p-4 mx-2 rounded-full bg-gray-200 px-8`}>Organization</div>
                <div className={`inline-block p-4 mx-2 rounded-full bg-gray-200 px-8`}>Date</div>
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {listOfWorkshops.map((workshop, index) => (
              <div className="rounded-lg overflow-hidden shadow-lg p-4" key={index}>
                <Link to={`/workshop/${workshop.id}`}>
                  
                  <div className='p-6'>
                  <img
                    src={"https://workshop.kasuwa24.ng/" + workshop.banner} 
                    alt='Logo'
                    style={{ width: '100%' }}
                  />
                </div>


                
                <div className="flex p-6">
                  
                  <div className="mr-4 flex flex-col items-center">
                    <div className="font-bold text-blue-500 text-xs text-center">{getMonthAbbreviation(workshop.date.substring(3, 5))}</div>
                    <div className="font-bold text-lg text-center">{workshop.date.substring(0, 2)}</div>
                    <div className="text-xs text-center">{workshop.date.substring(6, 11)}</div>
                  </div>
                  
                  
                  <div >
                  {workshop.title}

                    {/* <Link className="font-bold py-2" to={`/workshop/${workshop.id}`}></Link> */}
                   
                    <div style={{ maxHeight: '1.4em', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      {workshop.description}
                    </div>
                  </div>
                </div>
                </Link>





              </div>
            ))}
          </div>
            
          </div>
          {/* <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 gap-6">
            <div className="flex justify-center l p-6 pt-8" style={{ flex: '2' }}>
              <Link className='' to='result'>
                <button className="rounded-button-blue" style={{}}>Load More</button>
              </Link>
            </div>
          </div> */}
          {/* <div className="grid grid-cols-1 gap-6">
            <div className="flex pt-4 mt-8 mb-8">
              <div className="flex ">
                <div style={flexCenter100}>
                  <h2 className="text-left text-2xl sm:text-full md:text-4xl lg:text-4xl xl:text-4xl  ">
                    Past Workshops
                  </h2>
                </div>
              </div>
              <div className="flex flex-grow justify-end">
                <div className={`inline-block p-4 mx-2 rounded-full border-2 border-green-500 px-8 text-green-500 font-bold`}>Search Past Workshop</div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              <div className="rounded-lg overflow-hidden shadow-lg p-4">
                <div className='p-6'>
                  <img
                    src={building}
                    alt='Logo'
                    style={{ width: '100%' }}
                  />
                </div>
                <div className="flex p-6">
                  <div className="mr-4 flex flex-col items-center">
                    <div className="font-bold text-blue-500 text-xs text-center">Apr</div>
                    <div className="font-bold text-lg text-center">14</div>
                    <div className="text-xs text-center">2024</div>
                  </div>
                  <div>
                    <Link className="font-bold py-2" to="#"><div>The ABU EMDC Training Workshop on Printed Circuit Board</div></Link>
                    <div>We'll get you directly seated and inside for you to enjoy the show.</div>
                  </div>
                </div>
              </div>
              <div className="rounded-lg overflow-hidden shadow-lg p-4">
                <div className='p-6'>
                  <img
                    src={seat}
                    alt='Logo'
                    style={{ width: '100%' }}
                  />
                </div>
                <div className="flex p-6">
                  <div className="mr-4 flex flex-col items-center">
                    <div className="font-bold text-blue-500 text-xs text-center">Apr</div>
                    <div className="font-bold text-lg  text-center">14</div>
                    <div className="text-xs text-center">2024</div>
                  </div>
                  <div>
                    <Link className="font-bold py-2" to="#"><div>The ABU EMDC Training Workshop on Printed Circuit Board</div></Link>
                    <div>We'll get you directly seated and inside for you to enjoy the show.</div>
                  </div>
                </div>
              </div>
              <div className="rounded-lg overflow-hidden shadow-lg p-4">
                <div className='p-6'>
                  <img
                    src={seat}
                    alt='Logo'
                    style={{ width: '100%' }}
                  />
                </div>
                <div className="flex p-6">
                  <div className="mr-4 flex flex-col items-center">
                    <div className="font-bold text-blue-500 text-xs text-center">Apr</div>
                    <div className="font-bold text-lg  text-center">14</div>
                    <div className="text-xs text-center">2024</div>
                  </div>
                  <div>
                    <Link className="font-bold py-2" to="#"><div>The ABU EMDC Training Workshop on Printed Circuit Board</div></Link>
                    <div>We'll get you directly seated and inside for you to enjoy the show.</div>
                  </div>
                </div>
              </div>
            </div> */}
          <div className="grid grid-cols-1 gap-6">
            <div className="flex justify-center p-6 mt-8">
              <div style={flexCenter100}>
                <h2 className="text-2xl sm:text-2xl md:text-4xl lg:text-4xl xl:text-4xl font-bold pb-8 text-blue-500">
                  Some of our clients
                </h2>
              </div>
            </div>
          </div>
          <div className="mb-16">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
              <div className="rounded-lg overflow-hidden shadow-lg p-6 flex items-center">
                <img
                  src={nbte}
                  alt='Logo'
                />
                <div className="ml-4">National Board for Technical Education</div>
              </div>
              <div className="rounded-lg overflow-hidden shadow-lg p-6 flex items-center">
                <img
                  src={kp}
                  alt='Logo'
                />
                <div className="ml-4">Kaduna Polytechnic</div>
              </div>
              <div className="rounded-lg overflow-hidden shadow-lg p-6 flex items-center">
                <img
                  src={ncatz}
                  alt='Logo'
                />
                <div className="ml-4">Nigerian College of Aviation Tecnology Zaria</div>
              </div>
              <div className="rounded-lg overflow-hidden shadow-lg p-6 flex items-center">
                <img
                  src={abuz}
                  alt='Logo'
                />
                <div className="ml-4">Ahmadu Bello Univeristy Zaria</div>
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
              <div className="rounded-lg overflow-hidden shadow-lg p-6">
              </div>
              <div className="rounded-lg overflow-hidden shadow-lg p-6 flex items-center">
                <img
                  src={pti}
                  alt='Logo'
                />
                <div className="ml-4">Petroleum Training Institute</div>
              </div>
              <div className="rounded-lg overflow-hidden shadow-lg p-6 flex items-center">
                <img
                  src={iaict}
                  alt='Logo'
                />
                <div className="ml-4">Iya Abubakar Institute Of Communiation Technology</div>
              </div>
              <div className="rounded-lg overflow-hidden shadow-lg p-6">
              </div>
            </div>
          </div>
          <div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6 bg-blue-500">
              <div className="flex justify-center p-6">
                <div style={flexCenter100}>
                  <div className style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <div className='text-gray-600 text-left text-2xl sm:text-full md:text-4xl lg:text-4xl xl:text-4xl font-bold  ml-6' style={{ color: 'white' }}>Got a project<br /> or Partnership in<br /> Mind?</div>
                    <div className="grid grid-cols-2">
                      <div className="flex flex-col items-start p-6">
                        <div
                          style={{
                            backgroundColor: 'white',
                            borderRadius: '10%',
                            width: 'fit-content',
                            padding: '15px',
                          }}
                        >
                          <CallIcon />
                        </div>
                        <div style={{ color: 'white' }}>
                          <div className="font-bold py-2">Phone</div>
                          <div className="pb-2">+234 908 437 1023</div>
                          <div className="pb-2">+234 806 045 7408</div>
                        </div>
                      </div>
                      <div className="flex flex-col items-start p-6">
                        <div
                          style={{
                            backgroundColor: 'white',
                            borderRadius: '10%',
                            width: 'fit-content',
                            padding: '15px',
                          }}
                        >
                          <MessageIcon />
                        </div>
                        <div style={{ color: 'white' }}>
                          <div className="font-bold py-2">Email</div>
                          <div className="pb-2">info@zaptrance.ng</div>
                          <div className="pb-2"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col p-6">
                <div style={{ ...flexCenter100, flexDirection: 'column' }}>
                  <input
                    type="text"
                    placeholder="Your Name"
                    className="border-none p-2 mb-2"
                    style={{ background: 'transparent', borderBottom: '1px solid white', color: 'white', outline: 'none' }}
                  />
                  <input
                    type="email"
                    placeholder="Your E-mail Address"
                    className="border-none p-2 mb-2"
                    style={{ background: 'transparent', borderBottom: '1px solid white', color: 'white', outline: 'none' }}
                  />
                  <input
                    type="text"
                    placeholder="Your Project"
                    className="border-none p-2"
                    style={{ background: 'transparent', borderBottom: '1px solid white', color: 'white', outline: 'none' }}
                  />
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 gap-6">
                  <div className="flex justify-center l p-6" style={{ flex: '2' }}>
                    <Link className='' to='result'>
                      <button className="rounded-button-white" style={{ color: '#2353FF' }}>Send Message</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}
