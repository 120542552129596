import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from "../../Header";
import Footer from "../../Footer";
import AdminSideNav from './AdminSideNav';
import AdminMobileNavbar from './AdminMobileNavbar';
import HeaderAdmin from './HeaderAdmin';

import axios from 'axios';

import icon from '@mui/icons-material/Home';
import LoadingScreen from '../../LoadingScreen';

import AcceptOrDeclineModal from '../modals/AcceptOrDeclineModal';

const AdminDashboard = () => {
    const navigate = useNavigate();

    const [isDataLoading, setIsDataLoading] = useState(false);

  // Define state variables to store data
  const [workshops, setWorkshops] = useState([]);
  const [completed, setCompleted] = useState([]);
  const [participant, setParticipant] = useState([]);
  const [registrations, setRegistrations] = useState([]);

  const [selectedRow, setSelectedRow] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [registrationStatus, setRegistrationStatus] = useState(null);
const [errorMessage, setErrorMessage] = useState('');

//const [selectedRegistration, setSelectedRegistration] = useState('');
const [selectedRegistration, setSelectedRegistration] = useState([]);

const [showDialog, setShowDialog] = useState(false);


const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array to run this effect only once
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 640);
  };





  const handleRowClick = (registration, e) => {
    
    //alert(JSON.stringify(registration.modules, null, 2));
    //alert(registration.id);

    setSelectedRow(registration);
    setSelectedRegistration(registration);

    if (e.target.className.includes('text-green-500') || e.target.className.includes('text-red-500')) {
      // Clicked on "Approve" or "Decline" button, do nothing
      return;
    }


    setShowDialog(true);
    setIsModalOpen(true);

  };
  
  let token = localStorage.getItem('token');
  useEffect(() => {
    if (!token) {
      // Redirect to the home page if the token is null
      navigate('/');
    } else {
      // If the user is authenticated, call the handleData function
      handleData();
    }
    
  }, []);


  const handleData = async () => {
    setIsDataLoading(true);
    try {
      // Make the login request to obtain the data
      const response = await axios.get('https://workshop.kasuwa24.ng/api/admin', {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      // Check if the request was successful
      if (response.data.success) {
        setIsDataLoading(false);
        //alert("dashboard: " + JSON.stringify(response.data.data, null, 2));

        // Store the retrieved data in state variables
        setWorkshops(response.data.data.workshops);
        setCompleted(response.data.data.completed);
        setParticipant(response.data.data.participant);
        setRegistrations(response.data.data.registrations);
        

        //console.log(response.data.data.registrations);
      } else {
        setIsDataLoading(false);
        // Handle the case where the request was not successful
        console.error('Request failed:', response.data.message);
      }
    } catch (error) {
      setIsDataLoading(false);
      //alert(error);
      console.error('Request error:', error);
      // Handle request error, e.g., display an error message to the user
    }
  };

  const handleApproveClick = (reg) => {
    setShowDialog(false);
    //alert("a " + reg.id);
    updateStatus(reg.id, "confirmed");
  };

  const handleDeclineClick = (reg) => {
    setShowDialog(false);
    //alert("b " + reg.id);
    updateStatus(reg.id, "decline");
  };


  let count = 1;

  const updateStatus = async (value1, value2) => {

    //alert("token: " + token);

    const formData = new FormData();
    formData.append('status', value2);
    formData.append('comment', '.');
    

    //setLoading(true);
    //setIsDataLoading(true);
    try {
      // alert(`here: ${params.id}`);
      //console.log(value1, formData)
      const response = await axios.patch(`https://workshop.kasuwa24.ng/api/admin/applicants/${value1}`, formData, {
        headers: {
          'Content-Type': 'application/json', //'Content-Type': 'multipart/form-data', // Use multipart/form-data for file uploads
          Authorization: `Bearer ${token}`,
        },
      });

      // Check if the request was successful
      if (response.data.success) {
        const data = response.data.data;

        alert("Status updated Successfully");
        
        
        setIsDataLoading(false);

        handleData();
      } else {
        // Handle the case where the request was not successful
        console.error('Request failed:', response.data.message);
        setIsDataLoading(false);

        alert('Request failed:' + response.data.message);

        //alert("notok1");
      }
    } catch (error) {
      //alert(error);
      console.error('Request error:', error);
      setIsDataLoading(false);
      // Handle request error, e.g., display an error message to the user

      alert('Request error:' + error);
      //alert("notok2" + error);
    }
  };

  const closeAddModal = () => {
    setIsModalOpen(false);
    if (registrationStatus == "Registration Successful") {
      navigate("/");
    }
    
  };


    return (
      <div>
        <div>
        {isMobile ? (
        <AdminMobileNavbar /> // Use the mobile navbar component for mobile
      ) : (
        <null /> // Use the desktop navbar component for desktop
      )}
      </div>
        <div className="flex">
          
        {/* <AdminSideNav /> */}           
        {isMobile ? (
          null
         // Use the mobile navbar component for mobile
      ) : (
        <AdminSideNav /> // Use the desktop navbar component for desktop
      )}
           
            <div className="content flex-grow">
                <div className="inner-content">
                    {/* <HeaderAdmin /> */}
                    <div className="p-4" style={{ backgroundColor: '#F6F7FB' }}>
                       <div>
                       <div className='text-black px-4 pt-4'>
                            Zaptrance Systems
                        </div>
                        <div className='text-gray-600 px-4 pt-2 text-left text-2xl sm:text-full md:text-4xl lg:text-4xl xl:text-4xl font-bold mb-8'>
                            Dashboard
                        </div>
                       </div>
                        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-6">
                            <div className="text-center rounded-lg p-4 flex-1 m-2 flex-col text-black border-2 border-blue-500" >
                                <h1 style={{ fontSize: '32px', fontWeight: 'bold' }} className="py-2">
                                    {workshops}
                                </h1>
                                <div>Active Workshop</div>
                            </div>
                            <div className="text-center  rounded-lg p-4 flex-1 m-2 flex-col text-black border-2 border-blue-500">
                                <h1 style={{ fontSize: '32px', fontWeight: 'bold' }} className="py-2">
                                    {completed}
                                </h1>
                                <div>Completed Workshop</div>
                            </div>
                            <div className="text-center rounded-lg p-4 flex-1 m-2 flex-col text-black border-2 border-blue-500">
                                <h1 style={{ fontSize: '32px', fontWeight: 'bold' }} className="py-2">
                                    {participant}
                                </h1>
                                <div>Total Participants</div>
                            </div>
                        </div>
                        <div className="text-black p-4">Recent History</div>
                       

                          
                        {isDataLoading ? <div className="text-black p-4"><LoadingScreen /></div> : 
                        <div className='grid grid-cols-1 overflow-x-auto max-w-full' >

<table className='min-w-full bg-white shadow-md  overflow-hidden'>
                  
                  <thead>
                    <tr>
                      <th style={{backgroundColor: '#2353FF'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium uppercase text-white tracking-wider'>
                        S/N
                      </th>
                      <th style={{backgroundColor: '#2353FF'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium uppercase text-white tracking-wider'>
                        FullName
                      </th>
                      <th style={{backgroundColor: '#2353FF'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium uppercase text-white tracking-wider'>
                        Modules
                      </th>
                      <th style={{backgroundColor: '#2353FF'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium uppercase text-white tracking-wider'>
                        Email
                      </th>
                      <th style={{backgroundColor: '#2353FF'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium uppercase text-white tracking-wider'>
                        Institution
                      </th>
                      <th style={{backgroundColor: '#2353FF'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium uppercase text-white tracking-wider'>
                        Phone Number
                      </th>
                      <th style={{backgroundColor: '#2353FF'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium uppercase text-white tracking-wider'>
                        Attendance Type
                      </th>
                      <th style={{backgroundColor: '#2353FF'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium uppercase text-white tracking-wider'>
                        Payment Evidence
                      </th>
                      <th style={{backgroundColor: '#2353FF'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium uppercase text-white tracking-wider'>
                        Status
                      </th>
                      <th style={{backgroundColor: '#2353FF'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium uppercase text-white tracking-wider'>
                        Action
                      </th>

                      
                    </tr>
                  </thead>
                  
                  <tbody>
                    {registrations.map((registration) => (
                      <tr key={registration.id} onClick={(e) => handleRowClick(registration, e)} style={{ cursor: "pointer" }}>
                        <td className='px-6 py-4 whitespace-no-wrap border-b text-xs border-gray-200'>
                        {count++} {/* {registration.id} */}
                        </td>
                        <td className='px-6 py-4 whitespace-no-wrap border-b text-xs border-gray-200'>
                          {registration.firstName} {registration.middleName} {registration.lastName}
                        </td>
                        <td className='px-6 py-4 whitespace-no-wrap border-b text-xs border-gray-200'>
                          {Array.isArray(registration.modules) ? registration.modules.map((moduleObj) => moduleObj.module).join(', ') : 'No modules'}
                        </td>
                        <td className='px-6 py-4 whitespace-no-wrap border-b text-xs border-gray-200'>
                          {registration.email}
                        </td>
                        <td className='px-6 py-4 whitespace-no-wrap border-b text-xs border-gray-200'>
                          {registration.institution}
                        </td>
                        <td className='px-6 py-4 whitespace-no-wrap border-b text-xs border-gray-200'>
                          {registration.phoneNumber}
                        </td>
                        <td className='px-6 py-4 whitespace-no-wrap border-b text-xs border-gray-200'>
                          {registration.attendanceType}
                        </td>
                        <td className='px-6 py-4 whitespace-no-wrap border-b text-xs border-gray-200'>
                          {registration.payment}
                        </td>                        
                        <td className='px-6 py-4 whitespace-no-wrap border-b text-xs border-gray-200'>
                          {registration.status}
                        </td>
                        <td className='px-6 py-4 whitespace-no-wrap border-b text-xs border-gray-200'>
                          <span
                          className="text-green-500 cursor-pointer mr-2 "
                          onClick={() => {setShowDialog(false); handleApproveClick(registration); }}
                          >
                            Approve
                          </span>
                          <span
                          className="text-red-500 cursor-pointer "
                          onClick={() => {setShowDialog(false); handleDeclineClick(registration) }}
                          >
                            Decline
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                        </div>
                        }
                
             

                    </div>
                </div>
            </div>

<AcceptOrDeclineModal
              isOpen={isModalOpen}
              onRequestClose={closeAddModal}
              registrationStatus={registrationStatus}
              registrationError={errorMessage}
              registration={selectedRegistration}
              
            />


        </div>
      </div>
    );
}

export default AdminDashboard;
