import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import HeaderAdmin from './HeaderAdmin';
import AdminSideNav from './AdminSideNav';
import axios from 'axios';


const AdminLogout = () => {
  const navigate = useNavigate();
    

  useEffect(() => {

    let token = localStorage.setItem('token', "");
    navigate('/');
  }, [navigate]);
  

  return (
    <div></div>
  );
};

export default AdminLogout;
