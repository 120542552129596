import React from 'react';
import logo from '../assets/zlogo.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarker, faTwitter, faInstagram, faMessage, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faFacebook as faFacebookBrand, faTwitter as faTwitterBrand, faInstagram as faInstagramBrand } from '@fortawesome/free-brands-svg-icons';

export default function Footer() {
  return (
    <div>
      <div className='bottom-0 w-full bg-opacity-0 py-2 mt-12'>
        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 gap-4'>
          {/* Column 1 */}
          <div className='flex flex-col justify-start mx-8 mb-2'>
            <img src={logo} alt='Logo' className='mb-2' style={{ width: '50%', height: 'auto' }}/>
            <div><FontAwesomeIcon icon={faMapMarker} color="blue" className='ml-2 mr-2' />EE 2, Emir II Close, Unguwar Rimi, Kaduna</div>
            <div><FontAwesomeIcon icon={faMessage} color="blue" className='ml-2 mr-2'/>info@zaptrance.ng</div>
            
              
              <div>
              <a target="_blank" href="https://www.twitter.com/zaptrance">
                <FontAwesomeIcon icon={faTwitterBrand} color="blue" className='ml-2 mr-2'/></a>
            
            <a target="_blank" href="https://www.instagram.com/zaptrance"><FontAwesomeIcon  icon={faInstagramBrand} color="blue" className='mr-1'/></a>
             @zaptrance
            </div>
            <div>
              <FontAwesomeIcon  icon={faPhone} color="blue" className='ml-2 mr-2' />0908 437 1023, 0806 045 7408</div>
          </div>

          {/* Column 2 */}
          <div className='flex flex-col justify-start mx-8 mb-2'>
            <div className='font-bold'>Official info</div>
            <div>Home</div>
            <div>About Us</div>
            <div>Services</div>
            <div>Team</div>
          </div>

          {/* Column 3 */}
          <div className='flex flex-col justify-start mx-8 mb-2'>
            <div className='font-bold'>Subscribe To Get The Latest Updates, Offer & Promotion</div>
            <input
              type="text"
              placeholder="Enter your email address"
              className="border-none p-2 mb-2"
              style={{
                background: 'transparent',
                borderBottom: '1px solid',
                outline: 'none',
              }}
            />
          </div>

          {/* Column 4 */}
          <div className='flex flex-col justify-start mx-8'>
            <div className='font-bold mb-4'>Follow Us on Social Media To Get The Latest Updates</div>
            <div className="flex">
            <a target="_blank" href="https://www.facebook.com/zaptrance"><FontAwesomeIcon icon={faFacebookBrand} color="blue" className='ml-4 mr-4'/> </a>
              <a target="_blank" href="https://www.twitter.com/zaptrance"><FontAwesomeIcon icon={faTwitterBrand} color="blue" className='mr-4'/> </a>
              <a target="_blank" href="https://www.instagram.com/zaptrance"><FontAwesomeIcon icon={faInstagramBrand} color="blue" className='mr-4' /> </a>
            </div>
          </div>
        </div>
      </div>
      <div className='bottom-0 w-full bg-opacity-0 py-2 text-center'>© All right reserved Zaptrance, 2022</div>
    </div>
  );
}
