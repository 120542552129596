import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { navData } from '../../lib/AdminNavData';
import styles from './AdminSideNav.module.css';

import logo from '../../../assets/zlogo.png';
import LogoutIcon from '@mui/icons-material/Logout';

const SideNav = () => {
  const [open, setOpen] = useState(true);
  const [institutionLogo, setInstitutionLogo] = useState('');

  const toggleOpen = () => {
    setOpen(!open);
  };

  useEffect(() => {
    
  }, []);

  return (
    <div className={open ? styles.sidenav : styles.sidenavClosed}>
      
      <img className="w-56 h-20 object-scale-down " src={logo} alt="" />
      
      <div className='pt-8' ></div>
      {/* <button className={styles.menuBtn} onClick={toggleOpen}>
        {open ? <KeyboardDoubleArrowLeftIcon /> : <KeyboardDoubleArrowRightIcon />}
      </button> */}
      
      {navData.map((item) => (
        <div className={styles.navLinks}>
          <NavLink 
        key={item.id} 
        className={styles.sideitem} 
        to={`/${item.link}`}>
          {item.icon}
          <span className={styles.linkText}>{item.text}</span>
        </NavLink>
        </div>
      ))}

      <div className='bottom-0 w-full bg-opacity-0 pt-20 text-center'>

      <NavLink 
        key={7} 
        className={styles.sideitem} 
        to={`/logout`}>
          <LogoutIcon/>
          <span className={styles.linkText}>{'Log out'}</span>
        </NavLink>
            {/* Powered by Zaptrance <br/>World Wide Systems Ltd */}
        </div>
    </div>
  );
};

export default SideNav;