import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import building from '../../../assets/building.png';
import seat from '../../../assets/seat.png';
import axios from 'axios';

export default function ComponentWorkshop() {
  const [listOfWorkshops, setListOfWorkshops] = useState([]);
  const [filteredWorkshops, setFilteredWorkshops] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    handleData();
  }, []);

  function getMonthAbbreviation(monthString) {
    const months = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    if (typeof monthString === 'string' && monthString.length === 2) {
      const monthNumber = parseInt(monthString);

      if (!isNaN(monthNumber) && monthNumber >= 1 && monthNumber <= 12) {
        return months[monthNumber - 1];
      }
    }

    return null;
  }

  const handleData = async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://workshop.kasuwa24.ng/api/public', {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.data.success) {
        const workshopsData = response.data.data;
        setListOfWorkshops(workshopsData);
        setFilteredWorkshops(workshopsData);
        setLoading(false);
      } else {
        console.error('Request failed:', response.data.message);
      }
    } catch (error) {
      console.error('Request error:', error);
    }
  };

  return (
    <div>
      
      <div className="flex">
        <div className="text-black px-4 py-4" style={{ borderBottom: '2px solid #2353FF' }}>
          Workshops
        </div>

        <div className="flex flex-grow items-center justify-end">
          <div>
            <div className={`inline-block p-2 mx-2 rounded-full border-2 border-green-500 px-8 text-green-500 font-bold`}>Search</div>
          </div>
          <div>
            <div className={`inline-block p-2 mx-2 rounded-full bg-gray-200 px-8`}>Workshop</div>
          </div>
          <div>
            <div className={`inline-block p-2 mx-2 rounded-full bg-gray-200 px-8`}>Workshop Type</div>
          </div>
        </div>
      </div> 
     



      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {listOfWorkshops.map((workshop, index) => (
          <div className="rounded-lg overflow-hidden shadow-lg p-4" key={index}>
            <div className='p-6'>
              <img
                src={"https://workshop.kasuwa24.ng/" + workshop.banner}
                alt='Logo'
                style={{ width: '100%' }}
              />
            </div>
            <div className="flex p-6">
              <div className="mr-4 flex flex-col items-center">
                <div className="font-bold text-blue-500 text-xs text-center">{getMonthAbbreviation(workshop.date.substring(3, 5))}</div>
                <div className="font-bold text-lg text-center">{workshop.date.substring(0, 2)}</div>
                <div className="text-xs text-center">{workshop.date.substring(6, 11)}</div>
              </div>
              <div>
                <Link className="font-bold py-2">{workshop.title}</Link>

                <div style={{ maxHeight: '1.4em', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {workshop.description}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
