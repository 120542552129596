import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import styles from './AdminSideNav.module.css';
import { NavLink } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import { navData } from '../../lib/AdminNavData';
import logo from '../../../assets/zlogo.png';


export default function AdminMobileNavbar() {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const redStyle = {
    backgroundColor: '#2353FF',
    width: '10px',
    height: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '25%',
    padding: '16px',
  };

  return (
    <div className="" style={{backgroundColor: '#2353FF'}}>
      <div className="mobile-menu-button " onClick={toggleMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>

      {/* Mobile menu */}
      {isMenuOpen && (
        <div className="navbar-menu relative z-50">
          <div className="navbar-backdrop fixed inset-0 bg-gray-800 opacity-25" onClick={closeMenu}></div>
          <nav className="fixed top-0 left-0 bottom-0 flex flex-col w-5/6 max-w-sm py-6 pr-4 bg-white border-r overflow-y-auto">
            <div className="flex items-center mb-8">
              <a className="mr-auto text-3xl font-bold leading-none" href="#">
              <img className="w-56 h-20 object-scale-down " src={logo} alt="" />
              </a>
              <button className="navbar-close" onClick={closeMenu}>
                <FontAwesomeIcon
                  icon={faTimes}
                  color="white"
                  className="ml-4 mr-4 bg-blue-500"
                  style={{ ...redStyle }}
                />
              </button>
            </div>
            <div>
              <ul>
                {/* <li className="mb-1">
                  <a className="block p-4 text-sm font-semibold text-gray-400 hover:bg-blue-50 hover-text-blue-600 rounded" href="#">
                    Dashboard
                  </a>
                  <a className="block p-4 text-sm font-semibold text-gray-400 hover:bg-blue-50 hover-text-blue-600 rounded" href="#">
                    Workshop
                  </a>
                  <a className="block p-4 text-sm font-semibold text-gray-400 hover:bg-blue-50 hover-text-blue-600 rounded" href="#">
                    Support
                  </a>
                  <a className="block p-4 text-sm font-semibold text-gray-400 hover:bg-blue-50 hover-text-blue-600 rounded" href="#">
                    Profile
                  </a>
                  <a className="block p-4 text-sm font-semibold text-gray-400 hover:bg-blue-50 hover-text-blue-600 rounded" href="#">
                    Settings
                  </a>
                </li> */}
                {navData.map((item) => (
        <div className={styles.navLinks}>
          <NavLink 
        key={item.id} 
        className={styles.sideitem} 
        to={`/${item.link}`}>
          {item.icon}
          <span className={styles.linkText}>{item.text}</span>
        </NavLink>
        </div>
      ))}
                {/* Add more menu items here */}
              </ul>
            </div>
            {/* <div className="mt-auto">
              <div className="pt-6">
                <a className="block px-4 py-3 mb-3 leading-loose text-xs text-center font-semibold leading-none bg-gray-50 hover-bg-gray-100 rounded-xl" href="#">
                  Sign in
                </a>
                <a className="block px-4 py-3 mb-2 leading-loose text-xs text-center text-white font-semibold bg-blue-600 hover-bg-blue-700 rounded-xl" href="#">
                  Sign Up
                </a>
              </div>
              <p className="my-4 text-xs text-center text-gray-400">
                <span>Copyright © 2021</span>
              </p>
            </div> */}
            <div className="mt-auto">
              <div className="pt-6">
{/*                 
                <a className="block px-4 py-3 mb-2 leading-loose text-xs text-center text-white font-semibold bg-blue-600 hover-bg-blue-700 rounded-xl" href="#">
                  Log out
                </a> */}
                <NavLink 
        
        className={styles.sideitem} 
        to={`/logout`}>
          <LogoutIcon/>
          <span className={styles.linkText}>{'Log out'}</span>
        </NavLink>
              </div>
              {/* <p className="my-4 text-xs text-center text-gray-400">
                <span>Copyright © 2021</span>
              </p> */}
            </div>
          </nav>
        </div>
      )}

      {/* Regular navigation bar */}
      <nav className="desktop-nav">
        <ul>
          <li><a href="#">Home</a></li>
          <li><a href="#">About Us</a></li>
          <li><a href="#">Services</a></li>
          <li><a href="#">Pricing</a></li>
          <li><a href="#">Contact</a></li>
        </ul>
      </nav>
    </div>
  );
}
