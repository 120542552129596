import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import axios from 'axios';

import LoadingScreen from '../../LoadingScreen';

import { mkConfig, generateCsv, download } from "export-to-csv";


export default function ComponentRegistration() {
  const navigate = useNavigate();

  const [isDataLoading, setIsDataLoading] = useState(false);

  // Define state variables to store data
  const [workshops, setWorkshops] = useState([]);
  const [completed, setCompleted] = useState([]);
  const [participant, setParticipant] = useState([]);
  const [registrations, setRegistrations] = useState([]);

  const [selectedRow, setSelectedRow] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const csvConfig = mkConfig({ useKeysAsHeaders: true });

  const handleRowClick = (registration) => {
    setSelectedRow(registration);
    setIsModalOpen(true);
  };

  const getCSVData = () => {
    const csv = generateCsv(csvConfig)(registrations);
    return csv; // Return the CSV content
  };
  const handleDownloadCSV = () => {
    const csvContent = getCSVData();
    download(csvConfig)(csvContent);
  };
  

  let token = localStorage.getItem('token');
  useEffect(() => {
    if (!token) {
      // Redirect to the home page if the token is null
      navigate('/');
    } else {
      // If the user is authenticated, call the handleData function
      handleData();
    }
  }, []);

  const handleData = async () => {
    setIsDataLoading(true);
    try {
      // Make the login request to obtain the data
      const response = await axios.get('https://workshop.kasuwa24.ng/api/admin', {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      // Check if the request was successful
      if (response.data.success) {
        setIsDataLoading(false);
        //alert("dashboard: " + JSON.stringify(response.data.data, null, 2));

        // Store the retrieved data in state variables
        setWorkshops(response.data.data.workshops);
        setCompleted(response.data.data.completed);
        setParticipant(response.data.data.participant);
        setRegistrations(response.data.data.registrations);
      } else {
        setIsDataLoading(false);
        // Handle the case where the request was not successful
        console.error('Request failed:', response.data.message);
      }
    } catch (error) {
      setIsDataLoading(false);
      //alert(error);
      console.error('Request error:', error);
      // Handle request error, e.g., display an error message to the user
    }
  };

  let count = 1;

  return (
    <div>


{/* 
  <div className="flex ">
  <div className="text-black px-4 py-4" style={{ borderBottom: '2px solid #2353FF' }}>
  Registration
      </div>
  </div>

  <div className="flex flex-grow justify-end">
  <div className="flex flex-grow justify-end">
    <div className={`inline-block p-4 mx-2 rounded-full bg-gray-200 px-8 text-gray-500 `} >Search Workshop </div>
  </div>
    <div className={`inline-block p-4 mx-2 rounded-full bg-gray-200 px-8 text-gray-500 `}>Workshop Type</div>
  </div> */}

  <div className="flex">
        <div className="text-black px-4 py-4" style={{ borderBottom: '2px solid #2353FF' }}>
        Registration
        </div>

        <div className="flex flex-grow items-center justify-end">
          <div>
            <div className={`inline-block p-2 mx-2 rounded-full bg-gray-200 px-8`}>Search Workshop</div>
          </div>
          <div>
            <div className={`inline-block p-2 mx-2 rounded-full bg-gray-200 px-8`}>Workshop Type</div>
          </div>
        </div>
      </div> 


      
<div className="overflow-x-auto ">
  <div className="flex gap-6">
    <div className="text-xs text-black px-4 py-4">
      The ABU EDMC Training Workshop on Printed Circuit Board
    </div>
    

    <div className="flex justify-center l p-6 pt-8" style={{ flex: '2' }}>
      <div className="flex flex-grow justify-end">
        <div className="inline-block p-4 mx-2 rounded-full border-2 border-green-500 px-8 text-green-500 font-bold h-12 flex items-center justify-center">Search</div>
      </div>
      <button className="rounded-button-blue h-12" onClick={handleDownloadCSV}>Download CSV</button>   
    </div>



  </div>
</div>


<div className='flex'>
        <div className="overflow-x-auto ">

        {isDataLoading ? <div className="text-black p-4"><LoadingScreen /></div> : 

        
                        <table className='min-w-full bg-white shadow-md  overflow-hidden'>
                  
                  <thead>
                    <tr>
                      <th style={{backgroundColor: '#2353FF'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium uppercase text-white tracking-wider'>
                        S/N
                      </th>
                      <th style={{backgroundColor: '#2353FF'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium uppercase text-white tracking-wider'>
                        FullName
                      </th>
                      <th style={{backgroundColor: '#2353FF'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium uppercase text-white tracking-wider'>
                        Email
                      </th>
                      <th style={{backgroundColor: '#2353FF'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium uppercase text-white tracking-wider'>
                        Institution
                      </th>
                      <th style={{backgroundColor: '#2353FF'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium uppercase text-white tracking-wider'>
                        Phone Number
                      </th>
                      <th style={{backgroundColor: '#2353FF'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium uppercase text-white tracking-wider'>
                        Attendance Type
                      </th>
                      <th style={{backgroundColor: '#2353FF'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium uppercase text-white tracking-wider'>
                        Payment Evidence
                      </th>
                      <th style={{backgroundColor: '#2353FF'}} className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 font-medium uppercase text-white tracking-wider'>
                        Status
                      </th>
                      
                    </tr>
                  </thead>
                  
                  <tbody>
                  {registrations.map((registration) => (
                      <tr key={registration.id} onClick={() => handleRowClick(registration)} style={{ cursor: "pointer" }}>
                        <td className='px-6 py-4 whitespace-no-wrap border-b text-xs border-gray-200'>
                          {count++} {/* {registration.id} */}
                        </td>
                        <td className='px-6 py-4 whitespace-no-wrap border-b text-xs border-gray-200'>
                          {registration.firstName} {registration.middleName} {registration.lastName}
                        </td>
                        <td className='px-6 py-4 whitespace-no-wrap border-b text-xs border-gray-200'>
                          {registration.email}
                        </td>
                        <td className='px-6 py-4 whitespace-no-wrap border-b text-xs border-gray-200'>
                          {registration.institution}
                        </td>
                        <td className='px-6 py-4 whitespace-no-wrap border-b text-xs border-gray-200'>
                          {registration.phoneNumber}
                        </td>
                        <td className='px-6 py-4 whitespace-no-wrap border-b text-xs border-gray-200'>
                          {registration.attendanceType}
                        </td>
                        <td className='px-6 py-4 whitespace-no-wrap border-b text-xs border-gray-200'>
                          {registration.payment}
                        </td>
                        <td className='px-6 py-4 whitespace-no-wrap border-b text-xs border-gray-200'>
                          {registration.status}
                        </td>
                        
                      </tr>
                    ))}
                  </tbody>
                </table>
                
                }
        
      </div>
      {isModalOpen && (
  <div className="modal">
    <div className="modal-content">
      {/* <span className="close" onClick={() => setIsModalOpen(false)}>
        &times;
      </span> */}
      <div className='pt-18 px-4 pb-4'>
      <p  className="pb-6">Row Information:</p>
      
      <p>SN: {count++}</p>
      <p>ID: {selectedRow.id}</p>
      <p>Name: <strong>{`${selectedRow.firstName} ${selectedRow.middleName} ${selectedRow.lastName}`}</strong></p>
      <p>Email: {selectedRow.email}</p>
      <p>Institution: {selectedRow.institution}</p>
      <p>Phone Number: <strong>{selectedRow.phoneNumber}</strong></p>
      <p>Attendance Type: <strong>{selectedRow.attendanceType}</strong></p>
      {/* /<p>Payment Evidence: <strong>{selectedRow.payment}</strong></p> */}
      <p>Status: {selectedRow.status}</p>
      {/* <pre>{JSON.stringify(selectedRow, null, 2)}</pre> */}
      <p>Modules: <strong>{selectedRow.modules}</strong></p>
      {/* <p>Workshop: <strong>{selectedRow.workshop.title}</strong></p> */}

      {/* Display the payment evidence as an image */}
  {selectedRow.payment && (
    <div>
      <p>Payment Evidence:</p>
      <img
        src={`https://workshop.kasuwa24.ng/${selectedRow.payment}`}
        alt="Payment Evidence"
        style={{ 
          maxWidth: '75%', 
          height: 'auto',
        }}
      />
    </div>
  )}
      
      </div>
      <div className='flex flex-col justify-between w-full text-center pb-6 items-center'>
      <button className="rounded-button-blue pt-4 shadow-lg" onClick={() => setIsModalOpen(false)}>
        Close
      </button>        
      </div>
    </div>
  </div>
)}
</div>
    </div>
  );
}
