import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../assets/zlogo.png';

import axios from 'axios';
import LoadingScreen from './LoadingScreen';

export default function HeaderLogin() {

  const [email, setEmail] = useState('Enter your email');
  const [password, setPassword] = useState('Enter your password');
  const [isSigninLoading, setIsSigninLoading] = useState(false);

  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState('');



  const buttonContainerStyle = {
    // Center horizontally on small screens and add padding
    display: 'flex',
    justifyContent: 'center',
    padding: '1rem', // Adjust the padding as needed
  };

  const buttonStyle = {
    // Add your button styles here
  };

    // Function to handle the login button click
    const handleAdminLogin = async () => {

      setIsSigninLoading(true);
      setErrorMessage('');
  

      // if (
      //     email  == 'Enter your email'  ||  email  == '' ||
      //     password == 'Enter your password' ||  password  == ''
      //     ) {
              
      //         setErrorMessage("Login Failed: Please enter valid credentials");
      //     setIsSigninLoading(false);
      //     return;
      //   }
    
  
      
  
      try {
          // Make the login request to obtain the token
          const response = await axios.post('https://workshop.kasuwa24.ng/api/admin/login', {
              email,
              password,
          });

          localStorage.setItem('email', '');
          localStorage.setItem('password', '');
  
          // Check if the login was successful
          if (response.data.success && response.data.data.token) {
  
            setIsSigninLoading(false);
              
              // Token exists in the response, store it
              const token = response.data.data.token;
              //alert("login token: " + token)
              localStorage.setItem('token', token);
              //alert("stored token: " + localStorage.getItem('token'))
              //alert("login: " + JSON.stringify(response.data.data, null, 2));
  
  
              navigate("/zaptrance-dashboard");
              
              
          } else {
              setIsSigninLoading(false);
  
              setErrorMessage(response.data.message);
  
              // Handle the case where the login was not successful
              //console.error('Login failed:', response.data.message);
          }
      } catch (error) {
          setIsSigninLoading(false);

  
          //Request failed with status code 401
          setErrorMessage('Login failed. Please check your credentials and try again.');
  
          //console.error('Login error:', error);
          //console.error('Login error:', error);
          // Handle login error, e.g., display an error message to the user
      }
  };

  return (
    <div className='grid grid-cols-1 sm:grid-cols-2 items-center container mx-auto'>
      <div className='flex items-center'>
      <Link to="/">
      <img
          src={logo}
          alt='Logo'
        />
          </Link>
        <Link to="/">
            <button className='px-4'>
              Back to Home Page
            </button>
          </Link>

      </div>

      <div>
        <div style={buttonContainerStyle}>
          

            { isSigninLoading ? 
            <button className="rounded-button" ><LoadingScreen /></button>
             : 
            <button className="rounded-button" style={buttonStyle} >
              <strong>.</strong>
            </button> }
        </div>
      </div>
    </div>
  );
}
