import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import LandingPage from "./components/pages/landing/LandingPage";
import SpecificPage from "./components/pages/landing/SpecificPage";
import LoginPage from "./components/pages/landing/LoginPage";

import AdminDashboard from "./components/pages/admin/AdminDashboard";
import Workshop from "./components/pages/admin/Workshop";
import AdminSupport from "./components/pages/admin/AdminSupport";
import AdminProfile from "./components/pages/admin/AdminProfile";
import AdminSetting from "./components/pages/admin/AdminSetting";

import AdminLogout from './components/pages/admin/AdminLogout';

function App() {
  return (
    <Router>
      <Routes>


        <Route path='/' element={<LandingPage />}/>
        <Route path='/workshop/:id' element={<SpecificPage />}/>
        <Route path='/secure-login-zap' element={<LoginPage />} />   
        <Route path='/zaptrance-dashboard' element={<AdminDashboard />} />

        <Route path='/zaptrance-workshop' element={<Workshop />} />
        <Route path='/zaptrance-support' element={<AdminSupport />} />
        <Route path='/zaptrance-profile' element={<AdminProfile />} />
        <Route path='/zaptrance-setting' element={<AdminSetting />} />
        <Route path='/logout' element={<AdminLogout />} />  


        <Route path="/*" element={<div>NOT FOUND</div>} />
      </Routes>
    </Router>
  );
}

export default App;
