import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from "../../Header";
import Footer from "../../Footer";
import AdminSideNav from './AdminSideNav';
import HeaderAdmin from './HeaderAdmin';

import axios from 'axios';

import icon from '@mui/icons-material/Home';

const AdminProfile = () => {
    const navigate = useNavigate();

    let token = localStorage.getItem('token');
  useEffect(() => {
    if (!token) {
      // Redirect to the home page if the token is null
      navigate('/');
    } else {
      // If the user is authenticated, call the handleData function
      handleData();
    }
    
  }, []);
   
  const handleData = async () => {
    try {
      
    } catch (error) {
      //alert(error);
      console.error('Request error:', error);
      // Handle request error, e.g., display an error message to the user
    }
  };

  


    return (
        <div className="flex">
            <AdminSideNav />
            <div className="content flex-grow">
                <div className="inner-content">
                    {/* <HeaderAdmin /> */}
                    <div className="p-4" style={{ backgroundColor: '#F6F7FB' }}>
                        <div className='text-black px-4 pt-4'>
                        Zaptrance Systems
                        </div>
                        <div className='text-gray-600 px-4 pt-2 text-left text-2xl sm:text-full md:text-4xl lg:text-4xl xl:text-4xl font-bold mb-8'>
                            Profile
                        </div>
                        

                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdminProfile;
